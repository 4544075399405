var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c("h4", [_vm._v("工单信息")]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("工单号：")]),
          _vm._v(_vm._s(_vm.detail.ticket_id || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("园区：")]),
          _vm._v(_vm._s(_vm.detail.campus || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房：")]),
          _vm._v(_vm._s(_vm.detail.idcp_name || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("超时时间：")]),
          _vm._v(_vm._s(_vm.detail.olaTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("结单时间：")]),
          _vm._v(_vm._s(_vm.detail.end_time || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("是否超时：")]),
          _vm._v(_vm._s(_vm.detail.is_overdue || "--"))
        ])
      ]),
      _c("h4", { staticStyle: { "margin-top": "20px" } }, [_vm._v("设备详情")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "1200px" },
          attrs: { border: "", data: _vm.detail.arr }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "assetId", label: "固资号" }
          }),
          _c("el-table-column", { attrs: { prop: "sn", label: "SN" } }),
          _c("el-table-column", {
            attrs: { prop: "idcp_name", label: "一级机房" }
          }),
          _c("el-table-column", {
            attrs: { prop: "idc_name", label: "机房单元" }
          }),
          _c("el-table-column", {
            attrs: { prop: "is_ai_model", label: "是否AI服务器" }
          }),
          _c("el-table-column", { attrs: { prop: "model", label: "机型" } }),
          _c("el-table-column", { attrs: { prop: "pos_name", label: "机位" } }),
          _c("el-table-column", { attrs: { prop: "rack_name", label: "机架" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }