<template>
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- <h3 style="margin-bottom:50px">{{ detail.processTitle }}</h3> -->
    <h4>工单信息</h4>
    <div class="fielList">
      <p><span>工单号：</span>{{ detail.ticket_id || '--' }}</p>
      <p><span>园区：</span>{{ detail.campus || '--' }}</p>
      <p><span>机房：</span>{{ detail.idcp_name || '--' }}</p>
      <p><span>超时时间：</span>{{ detail.olaTime || '--' }}</p>
      <p><span>结单时间：</span>{{ detail.end_time || '--' }}</p>
      <p><span>是否超时：</span>{{ detail.is_overdue || '--' }}</p>
    </div>
    <h4 style="margin-top:20px">设备详情</h4>
    <el-table border :data="detail.arr" style="width: 1200px">
      <el-table-column prop="assetId" label="固资号"> </el-table-column>
      <el-table-column prop="sn" label="SN"> </el-table-column>
      <el-table-column prop="idcp_name" label="一级机房"> </el-table-column>
      <el-table-column prop="idc_name" label="机房单元"> </el-table-column>
      <el-table-column prop="is_ai_model" label="是否AI服务器"> </el-table-column>
      <el-table-column prop="model" label="机型"> </el-table-column>
      <el-table-column prop="pos_name" label="机位"> </el-table-column>
      <el-table-column prop="rack_name" label="机架"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { tpcDetail } from '@/api/monitor';
export default {
  name: 'tpcDetail',
  data() {
    return {
      fullscreenLoading: false,
      detail: {},
    };
  },
  created() {
    this.tpcDetail();
  },
  methods: {
    //详情接口
    tpcDetail() {
      this.fullscreenLoading = true;
      tpcDetail({ticket_id:this.$route.query.ticket_id})
        .then((res) => {
          this.detail = res.data.data;
          this.detail.arr = [{
            assetId:res.data.data.assetId,//固资号
            sn:res.data.data.sn,//SN
            idcp_name:res.data.data.idcp_name,//一级机房
            idc_name:res.data.data.idc_name,//机房单元
            is_ai_model:res.data.data.is_ai_model,//是否AI服务器
            model:res.data.data.model,//机型
            pos_name:res.data.data.pos_name,//机位
            rack_name:res.data.data.rack_name,//机架
          }]
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fielList {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
</style>
